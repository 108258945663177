.dashboard-wrapper{
  h3{
    margin-bottom:0;
  }
  .ant-collapse{
    border:none;
    background:transparent;
  }
  .ant-collapse > .ant-collapse-item{
    border-bottom:none;
    margin-bottom:16px;
    background: white;
  }

  .ant-collapse-content{
    border:none;
  }
  .ant-collapse-content-box{
    padding:0;
  }
  .common-card-wrapper{
    padding-top:0;
  }
}
li.recharts-legend-item{
  margin:5px;
}
