.ant-col-12 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.agency-header-buttons {
  .ant-btn,
  .ant-btn-link {
    margin-right: 5px;
    display: flex;
    align-items: center;
    color: #fff;
    background: #fc5027;
    border-color: #fc5027;
    svg {
      width: 25px;
      fill: white;
    }
    span {
      margin-left: 5px;
    }
  }
}

a.ant-btn.export-button {
  // As antd button type link by default is using  important in paddingtop
  padding-top: 8px !important;
}
@media screen and (max-width: 999px) {
  .agency-page-wrapper {
    &.ant-card-bordered {
      border: none;
      border-radius: 0;
    }
    & .ant-card-body {
      padding: 0;
    }
    .agency-header-buttons {
      padding: 16px;
    }
  }
  .ant-table-content {
    border: none;
  }
  #agency-contact-modal {
    .ant-modal {
      height: 100vh;
      top: 0;
      margin: 0;
      max-width: 100vw;
    }
    .ant-modal-content {
      border-radius: 0;
      height: 100vh;
      .ant-modal-close-x {
        font-size: 22px;
      }
      .ant-modal-header {
        border-bottom: none;
        padding: 16px;
        .ant-modal-title {
          padding-right: 23px;
          font-size: 20px;
        }
      }
      .ant-modal-body {
        padding: 0 16px 16px;
      }
      .ant-card.ant-card-bordered {
        border: none;
        h2 {
          margin-top: 10px;
          color: @label-color;
          font-size: 14px;
        }
        .ant-card-body {
          padding: 0;
          .contact-card {
            border: 1px solid @border-color;
            padding: 16px;
          }
        }
        .contact-scroll-wrapper {
          overflow-y: auto;
          height: calc(100vh - 149px);
          margin-top: 10px;
          min-height: 400px;
        }
        .contact-action-btn {
          bottom: 10px;
          right: 2px;
        }
        &.skeleton-card{
          border: 1px solid @border-color;
          padding: 16px;
        }
      }
      .row-dragging {
        z-index: 10000;
        width: 100%;
        border: none;
        .contact-card {
          width: 100%;
        }
      }
    }
  }
}
