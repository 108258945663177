.eqc-header {
  margin-bottom: 15px;
  .eqc-header-buttons {
    .ant-btn {
      margin-right: 5px;
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        fill: @white-color;
      }
      span {
        margin-left: 5px;
      }
    }
  }
  &-title {
    align-items: center;
    h1 {
      margin-bottom: 0px;
    }
    svg {
      fill: @primary-color;
      width: 35px;
      height: 35px;
      fill: @primary-color;
      margin-right: 15px;
      margin-bottom: 2px;
    }
  }
}

.details-header {
  align-items: center;
  h2 {
    margin-bottom: 0px;
    svg {
      margin-right: 5px;
      width: 30px;
      height: 30px;
      fill: @primary-color;
    }
  }
  .ant-btn {
    margin-right: 5px;
    display: flex;
    align-items: center;
    svg {
      width: 25px;
    }
    span {
      margin-left: 5px;
    }
  }
  .edit-button-eqc {
    svg {
      path {
        fill: @white-color;
      }
    }
  }
}

.details-header .export-button {
  &:hover {
    svg {
      fill: @primary-color;
    }
  }
  svg {
    fill: @grey-10;
  }
}

.stage-list-card {
  .eqc-stage-header-buttons {
    .import-button {
      background: @inactive-tag-background;
      svg {
        fill: @grey-10;
      }
      &:hover svg{
        fill: #ff784f;
      }
    }
    .add-button {
      background: @header-text-color;
      border: none;
      color: white;
      &:focus,&:hover{
        background:#202a3fd7;
      }
      &[disabled] {
        color: white;
        background: rgba(166, 170, 178);
      }
    }
    .ant-btn {
      margin-right: 5px;
      display: flex;
      align-items: center;
      svg {
        width: 26px;
      }
      span {
        margin-left: 5px;
      }
    }
  }

  .ant-table-content {
    border: none;
  }
}

.stage-item-card {
  .eqc-stage-header-buttons {
    .add-button {
      background: @header-text-color;
      border: none;
      color: white;
      &:focus,&:hover{
        background:#202a3fd7;
      }
      &[disabled] {
        color: white;
        background: rgba(166, 170, 178);
      }
    }
    .ant-btn {
      margin-right: 5px;
      display: flex;
      align-items: center;
      svg {
        width: 26px;
      }
      span {
        margin-left: 5px;
      }
    }
  }
}

.stageitem-modal {
  .ant-divider-dashed {
    border-color: #cdd5e0;
    border-width: 2px 0 0;
  }
  .ant-divider-horizontal {
    margin: 15px 0;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #f5f5f5;
  }
  .ant-checkbox-inner {
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: @header-text-color;
    border-color: @header-text-color;
  }
}

.grey-button {
  background: @light-blue;
  border-color: @light-blue;
  &:hover,
  &:focus {
    color: @grey-10;
    border-color: @grey-10;
  }
}

.optionsInput {
  width: 310px;
}

.row-dragging {
  background: @light-blue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-btn {
    background-color: transparent;
    padding: 0 8px;
    box-shadow: none;
    svg {
      fill: #fff;
    }
  }
}

.row-dragging td {
  padding: 0 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.eqc-module {
  .ant-table-thead > tr > th {
    color: @grey-10;
  }
  .ant-table-tbody > tr > td {
    color: @header-text-color;
  }
}

.drag-icon {
  cursor: grabbing;
  color: #999;
}
@media screen and( max-width: 999px) {
  .stage-list-card {
    &.ant-card-bordered {
      border: none;
      border-radius: 0;
      .ant-card-body {
        padding: 0;
        .header {
          padding: 16px 16px 0 16px;
        }
        h2 {
          margin-bottom: 0;
          font-size: 16px;
        }
      }
    }
  }
  .mobile-eqc-type-action-btn {
    button {
      display: block;
      margin-bottom: 10px;
    }
  }
  .eqc-type-scroll-wrapper {
    overflow-y: auto;
    height: calc(100vh - 291px);
    min-height: 400px;
  }
  #stage-item-modal {
    .ant-modal {
      height: 100vh;
      top: 0;
      margin: 0;
      max-width: 100vw;
    }
    .ant-modal-content {
      border-radius: 0;
      height: 100vh;
      .ant-modal-close-x {
        font-size: 22px;
      }
      .ant-modal-header {
        border-bottom: none;
        padding: 16px;
        .ant-modal-title {
          padding-right: 23px;
          font-size: 20px;
        }
      }
      .ant-modal-body {
        padding: 0 16px 16px;
      }
      .ant-card.ant-card-bordered {
        border: none;
        h2 {
          margin-bottom: 0;
          color: @label-color;
          font-size: 14px;
        }
        .ant-card-body {
          padding: 0;
          .stage-card {
            border: 1px solid @grey-4;
            padding: 16px;
          }
        }
        .stage-item-scroll-wrapper {
          overflow-y: auto;
          height: calc(100vh - 116px);
          margin-top: 10px;
          min-height: 400px;
        }
        .stage-item-action-btn {
          bottom: 10px;
          right: 2px;
        }
        &.skeleton-card{
          border: 1px solid @border-color;
          padding: 16px;
        }
      }
      .row-dragging {
        z-index: 10000;
        width: 100%;
        border: none;
        .ant-btn {
          svg {
            fill: rgb(242, 244, 247);
          }
        }
        .stage-card {
          width: 100%;
        }
      }
    }
  }
}
