.report-image-viewer {
  header {
    padding: 8px 16px;
  }
  &-content {
    padding: 40px;
    h2 {
      margin-bottom: 40px;
    }
    .ant-image {
      width: 15%;
      margin: 0 25px 25px 0;
    }
  }
}
