.approval-link {
  padding: 28px;
  .ant-table-content {
    border: 0px;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 0px;
  }

  tbody.ant-table-tbody {
    color: @header-text-color;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 5px 5px;
  }
  .ant-btn {
    margin-right: 5px;
    display: flex;
    align-items: center;
    svg {
      width: 25px;
      fill: @white-color;
      margin-right: 5px;
    }
  }

  .ant-descriptions {
    max-width: 70%;
    width: 100%;
  }

  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 4px;
  }

  .general-comment {
    background: @header-text-color;
    border: none;
    color: @white-color;
    &:focus,
    &:hover {
      background: #202a3fd7 !important; //to overwrite primary buttons specificity
    }
    &[disabled] {
      color: @white-color;
      background: rgba(166, 170, 178);
    }
  }

  .ant-image-mask:hover {
    opacity: 0;
  }
  .image-wrapper {
    & > div:nth-child(n + 3) {
      display: none;
    }
  }
  .common-item {
    .ant-descriptions-item-content {
      margin-bottom: 20px;
    }
  }
  .ant-descriptions-item-container {
    margin-right: 20px;
  }
  .ant-descriptions-item-container .ant-descriptions-item-label,
  .ant-descriptions-item-container .ant-descriptions-item-content {
    align-items: center;
  }

  .ant-descriptions-item-label {
    color: @grey-10;
  }
  .ant-descriptions-item-content {
    color: @header-text-color;
  }

  .previous-comment {
    background: @light-blue;
    border-color: @light-blue;
    color: @grey-10;
    margin-top: 10px;
  }
}

.comment-card {
  border: 2px solid @inactive-tag-background;
  border-radius: 16px;
  margin-top: 16px;
  h4 {
    color: @grey-10;
    margin: 0;
  }
  .ant-card-body {
    padding: 16px;
  }
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  .ant-image {
    .ant-image-img {
      object-fit: contain;
    }
  }
  .comment-time {
    font-size: 12px;
    font-weight: 400;
    color: @label-color;
    margin-bottom: 8px;
    line-height: 20px;
  }
  .comment {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
    line-height: 24px;
  }
}

.approver-stage-details {
  &-header {
    svg {
      fill: @primary-color;
    }
  }
}

.success-container {
  background-image: url(~assets/images/cover.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100%;
  flex: 1 1;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  &-content {
    padding: 32px 32px;
    font-size: 14px;
    position: relative;
    z-index: 5;
    h1 {
      font-size: 46px;
      margin-bottom: 80px;
    }
    svg {
      margin-bottom: 80px;
    }
  }
}

.success-container::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(242, 244, 247, 0.9);
}
