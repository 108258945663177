/* Sidebar Style */
.@{class-prefix}-app-sidebar {
  .transition(all 0.2s ease);
}

.ant-layout-sider {
  color: @nav-bar-text-color;
  background: rgba(0, 0, 0, 0.28);
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  width: @sidebar-width !important;
  overflow: hidden;

  @media screen and (max-width: (@screen-xl-min + 166px)) {
    width: 100% !important;
  }

  @media screen and (max-width: (@screen-xl-min - 200px)) {
    position: absolute;
    z-index: 999;
    height: 100%;
  }

  &-collapsed {
    width: @sidebar-mini-drawer-width !important;
    @media screen and (max-width: (@screen-xl-min - 200px)) {
      display: none;
    }
  }

  &.@{class-prefix}-collapsed-sidebar {
    width: 0 !important;
    min-width: 0 !important;
  }

  .sider-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 145px);
    .ant-menu {
      color: @header-text-color;
    }
  }

  .sider-footer {
    justify-content: left;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 16px;
    &-buttons {
      width: 100%;
      height: max-content;
      padding: 0px;
      .ant-btn {
        width: 50%;
        height: 45px;
      }
    }
    & svg {
      fill: @grey-10;
    }
    .ant-btn {
      border-radius: 0px;
    }
    > .ant-menu {
      background-color: @white-color;
    }

    & .ant-menu-submenu-title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    @media screen and (max-width: (@screen-xl-min - 820px)) {
      margin-top: 210px;
    }
  }

  .ant-layout-sider-trigger {
    position: absolute;
    background: @black-color;
    height: 56px;
    width: 100% !important;
    & svg {
      margin-top: 15px;
    }
  }
  .ant-popover {
    &-inner-content {
      justify-content: center;
      display: flex;
      padding: 10px 16px 0px;
      color: @header-text-color;
    }
  }
}

.@{class-prefix}-layout-sider-dark {
  background-color: @nav-dark-bg;
  color: @nav-dark-text-color;
}

.@{class-prefix}-layout-sider-header {
  justify-content: center;
  height: @layout-header-height;
  position: relative;
  z-index: 1;
  .flex-display(flex, row, nowrap);
  .align-items(center);

  @media screen and (max-width: @screen-xs-max) {
    height: @layout-header-height-res;
  }
  @media screen and (max-width: (@screen-xl-min - 200px)) {
    margin-left: 5px;
    justify-content: space-around;
  }

  & .@{class-prefix}-site-logo {
    display: block;

    .@{class-prefix}-mini-custom-sidebar & {
      display: block;
    }
  }

  & .@{class-prefix}-linebar {
    position: absolute;
    left: @sidebar-padding-lr - 10px;
    z-index: 1;
    top: 15px;
    .transition(all 0.3s ease-out);

    .ant-layout-sider-collapsed & {
      left: 20px;
    }
  }

  .@{class-prefix}-drawer-sidebar & {
    padding-left: @sidebar-padding-lr;
  }

  .@{class-prefix}-drawer-sidebar-dark & {
    background-color: fade(@black-color, 30%);
  }

  .@{class-prefix}-layout-sider-dark & {
    background-color: fade(@black-color, 30%);
    .box-shadow(none);
  }

  .@{class-prefix}-mini-custom-sidebar & {
    .justify-content(center);
    background-color: transparent;
  }

  .@{class-prefix}-custom-sidebar & {
    padding-left: @sidebar-padding-lr;
    background-color: transparent;
  }

  .@{class-prefix}-mini-custom-sidebar.ant-layout-sider-lite &,
  .@{class-prefix}-custom-sidebar.ant-layout-sider-lite & {
    .box-shadow(none);
    border-right: @border-width-base @border-style-base @border-color-split;
  }
}

.@{class-prefix}-sidebar-content {
  border-right: @border-width-base @border-style-base @primary-color;
  background: @header-text-color;
  .@{class-prefix}-layout-sider-dark &,
  .@{class-prefix}-drawer-sidebar-dark & {
    border-right: @border-width-base @border-style-base @primary-color;
  }
}

.@{class-prefix}-layout-sider-scrollbar {
  height: calc(100vh~ '-' @layout-header-height ~'-' 145px) !important;

  .framed-layout & {
    height: calc(
      100vh~ '-' @layout-header-height ~'-' 2 * @framed-layout-base ~'-' 145px
    ) !important;

    @media screen and (max-width: @screen-md-max) {
      height: calc(100vh ~'-' @layout-header-height) !important;
    }
  }

  .ant-layout-sider-collapsed & {
    height: calc(100vh~ '-' @layout-header-height ~'-' 91px) !important;

    .framed-layout & {
      height: calc(
        100vh~ '-' @layout-header-height ~'-' 2 * @framed-layout-base ~'-' 91px
      ) !important;

      @media screen and (max-width: @screen-md-max) {
        height: calc(100vh ~'-' @layout-header-height) !important;
      }
    }

    @media screen and (max-width: @screen-md-max) {
      height: calc(100vh ~'-' @layout-header-height) !important;
    }

    @media screen and (max-width: @screen-xs-max) {
      height: calc(100vh ~'-' @layout-header-height-res) !important;
    }
  }

  .@{class-prefix}-mini-custom-sidebar & {
    height: calc(100vh~ '-' @layout-header-height ~'-' 206px) !important;

    .framed-layout & {
      height: calc(
        100vh~ '-' @layout-header-height ~'-' 2 * @framed-layout-base ~'-' 206px
      ) !important;

      @media screen and (max-width: @screen-md-max) {
        height: calc(100vh ~'-' @layout-header-height) !important;
      }
    }
  }

  @media screen and (max-width: @screen-md-max) {
    height: calc(100vh ~'-' @layout-header-height) !important;
  }

  @media screen and (max-width: @screen-xs-max) {
    height: calc(100vh ~'-' @layout-header-height-res) !important;
  }
}

.@{class-prefix}-no-header-notifications {
  & + .@{class-prefix}-layout-sider-scrollbar {
    @media screen and (max-width: @screen-md-max) {
      height: calc(100vh~ '-' @layout-header-height ~'-' 145px) !important;
    }

    @media screen and (max-width: @screen-xs-max) {
      height: calc(100vh~ '-' @layout-header-height-res ~'-' 145px) !important;
    }
  }
}

.@{class-prefix}-sidebar-notifications {
  padding: @sidebar-padding-lr 10px 10px;
  margin: 0 (@sidebar-padding-lr - 10px) 10px;
  border-bottom: @border-style-base @border-width-base @border-color;

  .ant-layout-sider-dark &,
  .gx-drawer-sidebar-dark & {
    border-bottom-color: @nav-dark-text-color;
  }

  &.@{class-prefix}-no-header-notifications {
    @media screen and (max-width: @screen-md-max) {
      display: block;
    }
  }
}

.@{class-prefix}-app-nav {
  list-style: none;

  .flex-display(flex, row, wrap);
  .align-items(center);
  margin: 0 -20px;
  color: @primary-color;

  .ant-layout-sider-dark &,
  .gx-drawer-sidebar-dark & {
    color: @nav-dark-text-color;
  }

  & li {
    font-size: @font-size-lg + 4px;

    & svg {
      cursor: pointer;
      fill: @primary-color;
    }
  }

  .ant-layout-sider-collapsed & {
    display: none;
  }

  .@{class-prefix}-mini-custom-sidebar & {
    display: block;
    margin-top: 15px;

    & li:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.@{class-prefix}-avatar-name {
  cursor: pointer;

  .ant-layout-sider-collapsed & {
    display: none;
  }
}

.@{class-prefix}-avatar-row {
  .ant-layout-sider-collapsed & {
    margin-bottom: 0 !important;
  }

  & .ant-avatar {
    .transition(all 0.3s ease-out);
    position: relative;
    left: 0;

    .ant-layout-sider-collapsed & {
      margin: 0 !important;
      left: -10px;
    }
  }
}

.@{class-prefix}-menu-group .ant-menu-item-group-title {
  font-size: 16px;
  padding-top: 30px !important;
}

.@{class-prefix}-layouts-view {
  & .@{class-prefix}-pointer {
    display: block;
    margin-bottom: 15px;

    & img {
      width: 100%;
    }

    &.active {
      box-shadow: 0 0 10px 0 @primary-color;
      .border-radius(8px);
    }
  }
}

.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  display: none;
}

button.ant-btn.sider-menubar {
  display: block;
  position: absolute;
  left: 0;
  padding: 7px 7px;
  border-radius: 0px 8px 8px 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  z-index: 99999;
  top: 10px;
  width: 36px;
}

button.ant-btn.sider-menuarrow {
  background: none;
  border: none;
}

.ant-layout-sider-children {
  width: (@sidebar-width - 40);
  background: #f2f4f7;
}

.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  width: 100%;
  background: white;
  height: 45px;
}

.selected-button {
  svg {
    fill: @primary-color;
  }
}
