.users-header-buttons {
  .ant-btn,
  .ant-btn-link {
    &:not(:last-child) {
      margin-right: 5px;
    }
    display: flex;
    align-items: center;
    background: #fc5027;
    border-color: #fc5027;
    color: white;
    svg {
      width: 25px;
      fill: white;
    }
  }
  .ant-btn-link > *:not(:last-child) {
    margin-right: 5px;
  }
}
.users {
  @media screen and (max-width: 999px) {
    padding: 0 16px 16px;
  }
}

.user-action-btn {
  top: 5px;
  right: 0px;
}
